import React from 'react'
import { withAuth } from '~contexts/authContext/authContext'

const Test = () => {
	return (
		<div>
			Lorem ipsum, dolor sit amet consectetur adipisicing elit. Velit libero,
			nostrum, tempore voluptatibus odio voluptatum blanditiis, nobis ullam
			delectus sequi ex. Iste deleniti aliquam, fugiat aspernatur numquam sed
			expedita ullam.
		</div>
	)
}

export default withAuth(Test)
